import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const ContentPage = ({ className, header, html }) => {
  return (
    <>
      <PageSection className={className}>
        <Row>
          <SectionHeader header={header} />
        </Row>
        <Row>
          <Col md={12} >
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Col>
        </Row>
      </PageSection>
    </>
  );
};

ContentPage.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

ContentPage.defaultProps = {
  className: '',
};

export default ContentPage;
